import { Disclosure, Menu } from "@headlessui/react";
import { ColorsCSS as Colors, Icon } from "@zapier/design-system";
import { transparentize } from "color2k";
import { styled } from "lib/theme";
import Link from "next/link";
import { forwardRef } from "react";
import IconInterfaces from "../../../public/icon-interfaces.svg";

const StyledDisclosure = styled.nav`
  /* If you change the styles here, you will most likely need to change the code in packages/service/e2e/tests/editor-page/custom-theme.e2e.ts */
  background-color: ${({ theme }) => theme.app.colors.white};
  box-shadow: 0 1px 0 ${({ theme }) => theme.app.colors.lightGray};
  font-family: ${({ theme }) => theme.app.fontFamily};
`;

const StyledRoot = styled.div`
  margin: 0 auto;
  padding: 0 18px;
  max-width: 80rem;

  @media screen and (min-width: 640px) {
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 2rem;
  }
`;

const StyledRootInner = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 70px;
`;

const StyledNav = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: flex-start;
`;

const StyledDisclosurePanelWrapper = styled.div`
  @media (min-width: 640px) {
    display: none;
  }
`;

const StyledDisclosurePanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 0.5rem;
`;

export type ResponsiveNavProps = {
  items: {
    name: string;
    href: string;
    current: boolean;
    target?: "_self" | "_blank" | "_parent" | "_top";
  }[];
  profileItems: {
    name: string;
    onClick: () => void;
  }[];
  interfaceName?: string;
  name?: string | null;
  email?: string;
  avatarUrl?: string;
  avatarAltText?: string | null;
  includeLogo: boolean;
  logoHref?: string;
  logoAltText?: string;
  logo?: {
    name: string;
    url: string;
    showLogoOnLoginPage: boolean;
    height: number;
  } | null;
};

export const ResponsiveNav = forwardRef<HTMLElement, ResponsiveNavProps>(
  function ResponsiveNav(props, ref) {
    if (
      !props.name &&
      !props.email &&
      !props.items.length &&
      !props.includeLogo
    ) {
      return null;
    }

    return (
      <Disclosure
        as={StyledDisclosure}
        ref={ref}
        data-testid="builder-page-nav"
      >
        {({ open }) => {
          return (
            <>
              <StyledRoot>
                <StyledRootInner>
                  <MobileMenuTrigger open={open} />

                  <StyledNav>
                    <NavLogo
                      logo={props.logo}
                      includeLogo={props.includeLogo}
                      interfaceName={props.interfaceName}
                      logoHref={props.logoHref}
                      logoAltText={props.logoAltText}
                    />

                    <PagesLinks items={props.items} />
                  </StyledNav>

                  {props.name ? (
                    <ProfileDropdown
                      avatarUrl={props.avatarUrl}
                      avatarAltText={props.avatarAltText}
                      name={props.name}
                      email={props.email}
                      profileItems={props.profileItems}
                    />
                  ) : null}
                </StyledRootInner>
              </StyledRoot>

              <MobileMenu
                profileItems={props.profileItems}
                items={props.items}
              />
            </>
          );
        }}
      </Disclosure>
    );
  }
);

const StyledNavItems = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledNavItem = styled.a<{ $current: boolean }>`
  padding: 8px 15px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 9999px;
  text-decoration: none;

  transition:
    background-color 0.14159s ease-out,
    color 0.14159s ease-out;

  background-color: ${({ theme, $current }) =>
    $current ? transparentize(theme.app.colors.primary, 0.95) : "transparent"};
  color: ${({ theme, $current }) =>
    $current
      ? theme.app.colors.primary
      : transparentize(theme.app.colors.darkGray, 0.1)};

  &:hover {
    color: ${({ theme }) => theme.app.colors.primary};
  }
`;

function PagesLinks({ items }: { items: ResponsiveNavProps["items"] }) {
  return (
    <StyledNavItemsWrapper>
      <StyledNavItems>
        {items.map((item) => (
          <Link
            key={item.name}
            href={item.href}
            legacyBehavior
            target={item.target}
          >
            <StyledNavItem
              href={item.href}
              $current={item.current}
              aria-current={item.current ? "page" : undefined}
              target={item.target}
            >
              {item.name}
            </StyledNavItem>
          </Link>
        ))}
      </StyledNavItems>
    </StyledNavItemsWrapper>
  );
}

const StyledDisclosureButtonWrapper = styled.div`
  position: absolute;

  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;

  @media screen and (min-width: 640px) {
    display: none;
  }
`;

const StyledDisclosureButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 9px 6px 6px;
  gap: 5px;
  cursor: pointer;

  border-radius: 5px;
  border: 1px solid
    ${({ theme }) => transparentize(theme.app.colors.secondaryText, 0.7)};

  color: ${({ theme }) => theme.app.colors.secondaryText};

  transition:
    color 0.2s ease-out,
    background-color 0.2s ease-out,
    border-color 0.2s ease-out;

  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  &:hover {
    color: ${({ theme }) => theme.app.colors.primary};
    background-color: ${({ theme }) =>
      transparentize(theme.app.colors.primary, 0.95)};
    border-color: ${({ theme }) =>
      transparentize(theme.app.colors.primary, 0.7)};
  }

  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.app.colors.primary} 0px 0px 0px 2px;
  }
`;

function MobileMenuTrigger({ open }: { open: boolean }) {
  return (
    <StyledDisclosureButtonWrapper>
      <Disclosure.Button as={StyledDisclosureButton} id="mobile-menu-button">
        {open ? (
          <Icon name="formX" size={20} aria-hidden="true" />
        ) : (
          <Icon name="navMenu" size={20} aria-hidden="true" />
        )}
        Menu
      </Disclosure.Button>
    </StyledDisclosureButtonWrapper>
  );
}

const StyledDisclosureNavItem = styled.a<{ $current: boolean }>`
  display: block;
  padding: 12px 35px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 9999px;
  text-decoration: none;
  width: max-content;
  margin: 0 auto;
  cursor: pointer;

  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;

  ${({ theme, $current }) =>
    $current
      ? `
  color: ${theme.app.colors.primary};
  background-color: ${transparentize(theme.app.colors.primary, 0.95)};`
      : `
  color: ${theme.app.colors.secondaryText};
  background-color: ${theme.app.colors.white};

  :hover {
    color: ${theme.app.colors.primary};
  }`}
`;

function MobileMenu({
  profileItems,
  items,
}: {
  profileItems: ResponsiveNavProps["profileItems"];
  items: ResponsiveNavProps["items"];
}) {
  return (
    <Disclosure.Panel as={StyledDisclosurePanelWrapper}>
      <StyledDisclosurePanel>
        {items.map((item) => (
          <Disclosure.Button
            key={item.name}
            as={StyledDisclosureNavItem}
            $current={item.current}
            href={item.href}
            aria-current={item.current ? "page" : undefined}
          >
            {item.name}
          </Disclosure.Button>
        ))}
        {profileItems.map((profileItem) => (
          <Disclosure.Button
            key={profileItem.name}
            as={StyledDisclosureNavItem}
            $current={false}
            onClick={profileItem.onClick}
          >
            {profileItem.name}
          </Disclosure.Button>
        ))}
      </StyledDisclosurePanel>
    </Disclosure.Panel>
  );
}

const StyledProfileWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 90px;
  align-items: center;

  @media (min-width: 640px) {
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    padding-right: 0;
  }
`;

const StyledMenu = styled.div`
  position: relative;
`;

const StyledMenuItems = styled.div`
  position: absolute;
  right: 0;
  z-index: 10;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${({ theme }) => theme.app.colors.white};
  transform-origin: top right;
  width: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 15px 25px 0px;

  &:focus {
    outline: none;
  }
`;

const StyledConsumerInfo = styled.button`
  display: flex;
  align-items: center;
  justify-items: start;
  background-color: ${({ theme }) => theme.app.colors.white};
  padding: 6px;
  cursor: pointer;

  border-radius: 8px;

  transition: background-color 0.2s ease-out;

  pointer-events: none;

  @media (min-width: 640px) {
    pointer-events: auto;
  }

  &[data-headlessui-state="open"],
  &:hover {
    background-color: ${({ theme }) =>
      transparentize(theme.app.colors.primary, 0.95)};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.app.colors.primary};
  }

  & > img {
    grid-area: avatar;
    border-radius: 100%;
    outline: 2px solid ${({ theme }) => theme.app.colors.superLightGray};
  }

  & > svg {
    display: none;
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;

    & path {
      fill: ${({ theme }) => theme.app.colors.secondaryText};
    }

    @media (min-width: 640px) {
      display: block;
      margin-left: 10px;
    }
  }

  &[data-headlessui-state="open"] > svg {
    transform: rotate(180deg);
  }

  & > span:only-of-type {
    display: none;

    @media (min-width: 768px) {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }
  }

  & > span:only-of-type > span:first-of-type {
    font-size: 15px;
    line-height: 24px;
    color: ${({ theme }) => theme.app.colors.text};
  }

  & > span:only-of-type > span:last-of-type:not(:only-of-type) {
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.app.colors.secondaryText};
  }
`;

const StyledMenuItem = styled.a<{ $active: boolean }>`
  display: block;
  padding: 0.5rem 15px;
  color: ${({ theme }) => theme.app.colors.secondaryText};
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  transition: color 0.14159s ease-out;

  ${({ theme, $active }) =>
    $active &&
    `
    color: ${theme.app.colors.primary};
  `}
`;

function ProfileDropdown({
  avatarUrl,
  avatarAltText,
  name,
  email,
  profileItems,
}: {
  avatarUrl: ResponsiveNavProps["avatarUrl"];
  avatarAltText: ResponsiveNavProps["avatarAltText"];
  name: ResponsiveNavProps["name"];
  email: ResponsiveNavProps["email"];
  profileItems: ResponsiveNavProps["profileItems"];
}) {
  return (
    <StyledProfileWrapper>
      <Menu as={StyledMenu}>
        <div>
          <Menu.Button as={StyledConsumerInfo}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={avatarUrl}
              alt={avatarAltText ?? name ?? "User avatar"}
              height={36}
              width={36}
              onError={(event) => {
                const imgEl = event.target as HTMLImageElement;
                if (!/\/icon\-interfaces\.svg$/.test(imgEl.src)) {
                  imgEl.src = "/icon-interfaces.svg";
                }
              }}
            />
            <span>
              <span>{name}</span>
              <span>{email}</span>
            </span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <path d="M9 12.975L14.25 8.57999V6.61499L9 11.025L3.75 6.61499V8.57999L9 12.975Z" />
              </g>
            </svg>
          </Menu.Button>
        </div>
        <Menu.Items as={StyledMenuItems}>
          {profileItems.map((profileItem) => (
            <Menu.Item key={profileItem.name}>
              {({ active }) => (
                <StyledMenuItem $active={active} onClick={profileItem.onClick}>
                  {profileItem.name}
                </StyledMenuItem>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </StyledProfileWrapper>
  );
}

const StyledNavItemsWrapper = styled.div`
  display: none;

  @media (min-width: 640px) {
    display: flex;
    align-items: center;
  }
`;

const StyledNavLogo = styled.img<{ $height: number }>`
  width: auto;
  height: ${({ $height }) => $height}px;
`;

const StyledNavInterfaceName = styled.h4`
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.14159s;

  @media (max-width: 480px) {
    max-width: 20ch;
    overflow-x: hidden;
  }

  &:visited {
    color: ${Colors.black};
  }
`;

const StyledNavLogosWrapper = styled.a<{ $disabled: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 4px;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};

  padding-inline: 1.5rem;
  padding-block: 0.5rem;
  margin-left: -1.5rem;

  color: ${Colors.black};
  text-decoration: none;

  &:visited {
    color: ${Colors.black};
  }
  &:hover {
    background: ${({ theme }) =>
      transparentize(theme.app.colors.primary, 0.95)};
  }
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.app.colors.primary} 0px 0px 0px 2px;
  }
`;

function NavLogo({
  logo,
  logoHref,
  includeLogo,
  logoAltText,
  interfaceName,
}: {
  logo: ResponsiveNavProps["logo"];
  logoHref: ResponsiveNavProps["logoHref"];
  logoAltText: ResponsiveNavProps["logoAltText"];
  includeLogo: ResponsiveNavProps["includeLogo"];
  interfaceName: ResponsiveNavProps["interfaceName"];
}) {
  return (
    <Link href={logoHref ? logoHref : "#"} legacyBehavior>
      <StyledNavLogosWrapper $disabled={!logoHref} href={logoHref}>
        {includeLogo ? (
          <StyledNavLogo
            $height={logo?.height ?? 24}
            src={logo?.url ?? "/icon-interfaces.svg"}
            alt={logoAltText}
          />
        ) : (
          <IconInterfaces
            width={logo?.height ?? 24}
            height={logo?.height ?? 24}
          />
        )}
        {!includeLogo && interfaceName ? (
          <StyledNavInterfaceName>{interfaceName}</StyledNavInterfaceName>
        ) : null}
      </StyledNavLogosWrapper>
    </Link>
  );
}
